import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DatabaseService } from 'src/app/_services/database.service';

@Component({
  selector: 'app-product-add-lms',
  templateUrl: './product-add-lms.component.html',
  styleUrls: ['./product-add-lms.component.scss']
})
export class ProductAddLmsComponent implements OnInit {
  
  constructor(private _location: Location,public db:DatabaseService,public actRoute:ActivatedRoute) { }
  
  ngOnInit() {
    if(this.actRoute.snapshot.params.id){
      this.getDetail(this.actRoute.snapshot.params.id)
    }    else{
      this.formsInit({});
    }
    this.getData()
  }
  data:any={}
  filteredData:any={};
  getData(){
    
    this.db.postReq({start:0,limit:1000,filter:{}},'master/product_category').subscribe(resp=>{
      if(resp['status'] == 'success'){
        this.data.categoryData = resp['result'].data        ;
        this.filteredData.categoryData = resp['result'].data        
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    })

    this.db.postReq({start:0,limit:1000,filter:{}},'master/brand').subscribe(resp=>{
      if(resp['status'] == 'success'){
        this.data.brandData = resp['result'].data        ;
        this.filteredData.brandData = resp['result'].data        
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    })
 

  

  
     
  }

  getDetail(id){
    this.db.presentLoader();
    
    this.db.postReq({'product_id':id},'master/product_detail').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.formsInit(resp['data']['detail']);
        
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errHandler(err);
    })
    
  }
  form: FormGroup;
  
  formsInit(data:any) {
    this.form = this.db.formBuilder.group({
    
     product_name :[data.product_name ? data.product_name : null,[]],
     product_id :[data.id ? data.id : null ,[]],
     product_code :[data.product_code ? data.product_code : null,[]],
     product_type :['lms',[]],
     product_category :[data.product_category ? data.product_category : null,[]],
     architect_point :[data.architect_point ? data.architect_point : null,[]],  
     dealer_point :[data.dealer_point ? data.dealer_point : null,[]],  
    })
  }
  onSubmit(){
    if(this.form.invalid){
    this.form.markAllAsTouched()
      return
    }
    console.log("FormValues",this.form.value);
    this.db.presentLoader();
    this.db.postReq(this.form.value,this.actRoute.snapshot.params.id ? 'master/update_product' : 'master/add_product').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.db.successAlert(resp['status'],resp['message']);
        this.backClicked();
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errDismissLoader();
    })
    
  }
  
  backClicked() {
    this._location.back();
  }
  
}
