import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { DatabaseService } from 'src/app/_services/database.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-travel-add',
  templateUrl: './travel-add.component.html'
})
export class TravelAddComponent implements OnInit {
  
  data:any=[];
  autoFocus: boolean = true;
  
  constructor(public db:DatabaseService,public formBuilder:FormBuilder,private _location: Location) { }
  
  ngOnInit() {
    this.data = new Array(8);
    this.getsalesData();
    this.todayDate = moment().format('YYYY-MM-DD')
    this.initForm();
    this.db.getStates();
    this.getDrTypes();
    this.gettypes();
  }
  todayDate:any
  form: FormGroup;  
  initForm() {
    
    this.form = this.formBuilder.group({
      dr_type: ["", [Validators.required]],
      dr_id: ["", [Validators.required]],
      sales_user: ["", [Validators.required]],
      description: ["", [Validators.required]],
      travel_from :[moment().format('YYYY-MM-DD'), [Validators.required]],
      travel_to :[moment().format('YYYY-MM-DD'), [Validators.required]],
      travel_type :["", [Validators.required]],
      state_name :["", [Validators.required]],
      district_name :["", [Validators.required]],
      area :[[], [Validators.required]],
      city :["", [Validators.required]]
    });
    
  }
  typeHandler(){
    if(!this.form.value.travel_type.toLowerCase().includes('area')){
      this.form.get('state_name').clearValidators();
      this.form.get('state_name').updateValueAndValidity();
      
      this.form.get('district_name').clearValidators();
      this.form.get('district_name').updateValueAndValidity();
      
      this.form.get('area').clearValidators();
      this.form.get('area').updateValueAndValidity();
      
      this.form.get('city').clearValidators();
      this.form.get('city').updateValueAndValidity();
      
      this.form.get('dr_type').setValidators([Validators.required]);
      this.form.get('dr_type').updateValueAndValidity();
      
      this.form.get('dr_id').setValidators([Validators.required]);
      this.form.get('dr_id').updateValueAndValidity();
      
    }else{
      this.form.get('state_name').setValidators([Validators.required]);
      this.form.get('state_name').updateValueAndValidity();
      
      this.form.get('district_name').setValidators([Validators.required]);
      this.form.get('district_name').updateValueAndValidity();
      
      this.form.get('area').setValidators([Validators.required]);
      this.form.get('area').updateValueAndValidity();
      
      this.form.get('city').setValidators([Validators.required]);
      this.form.get('city').updateValueAndValidity();
      
      this.form.get('dr_type').clearValidators();
      this.form.get('dr_type').updateValueAndValidity();
      
      this.form.get('dr_id').clearValidators();
      this.form.get('dr_id').updateValueAndValidity();
    }
  }
  salesData:any=[];
  filteredSalesData:any=[];
  getsalesData(){
    let reqData =  {
      "limit" :100000,
      "start" : 0,
      "filter" : {
        // "cust_type_id" :this.form.value.dr_type.id
      }
    }
    this.db.presentLoader();
    this.db.postReq(reqData,"followup/sales_user").subscribe(async (resp:any) => {
      await this.db.dismissLoader();
      if(resp['message']=='Invalid token'){
        this.db.sessionExpire();
        return;
      }
      // this.db.requestSent = true;
      if(resp['status']=='success'){
        this.salesData = resp['result'].data;
        this.filteredSalesData = resp['result'].data;
        
        // if(this.data.id){
        //   let i = this.salesData.findIndex(r=> r.id== this.data.user_id);
        //   this.form.controls['sales_user'].setValue(this.salesData[i]);
        // }
        
      }else{
        this.db.presentAlert(resp['status'],resp['message']);
      }
    },async err => {
      // this.db.errorAlert(err); 
    })  
  }
  backClicked() {
    this._location.back();
  }
  
  drType:any=[];
  getDrTypes(){
    this.db.presentLoader();
    this.db.postReq({},"travel_plan/dr_type").subscribe(async (resp:any) => {
      await this.db.dismissLoader();
      // this.db.requestSent = true;
      if(resp['message']=='Invalid token'){
        this.db.sessionExpire();
        return;
      }
      if(resp['status']=='success'){
        this.drType = resp['result'].data;
        
      }else{
        this.db.presentAlert(resp['status'],resp['message']);
      }
    },async err => {
      // this.db.errorAlert(err); 
    })  
  }
  type:any=[];
  gettypes(){
    this.db.presentLoader();
    this.db.postReq({},"travel_plan/type").subscribe(async (resp:any) => {
      await this.db.dismissLoader();
      // this.db.requestSent = true;
      if(resp['message']=='Invalid token'){
        this.db.sessionExpire();
        return;
      }
      if(resp['status']=='success'){
        this.type = resp['result'].data;
        
      }else{
        this.db.presentAlert(resp['status'],resp['message']);
      }
    },async err => {
      // this.db.errorAlert(err); 
    })  
  }
  
  drData:any=[];
  filteredDrData:any=[];
  
  getDrData(){
    let reqData =  {
      "limit" :100000,
      "start" : 0,
      "filter" : {
        "cust_type_id" :this.form.value.dr_type.id
      }
    }
    this.db.presentLoader();
    this.db.postReq(reqData,"travel_plan/dr_list").subscribe(async (resp:any) => {
      await this.db.dismissLoader();
      if(resp['message']=='Invalid token'){
        this.db.sessionExpire();
        return;
      }
      // this.db.requestSent = true;
      if(resp['status']=='success'){
        this.drData = resp['result'].data;
        this.filteredDrData= resp['result'].data;
       
      }else{
        this.db.presentAlert(resp['status'],resp['message']);
      }
    },async err => {
      // this.db.errorAlert(err); 
    })  
  }
  onSubmit(){
    console.log(this.form);
    // return
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    let areaString = ''
    this.form.value.area.map((r,i)=>{
      areaString = areaString+r;
      if(i + 1  != this.form.value.area.length){
        areaString = areaString+',';
      }
    })
    let reqData = this.form.value
    console.log(this.form);
    reqData.customer_id = this.form.value.dr_id.id,
    reqData.cust_type_id = this.form.value.dr_type.id,
    reqData.cust_type = this.form.value.dr_type.name,
    reqData.travel_from  = moment(this.form.value.travel_from).format('YYYY-MM-DD'),
    reqData.travel_to  = moment(this.form.value.travel_to).format('YYYY-MM-DD'),
    reqData.description  = this.form.value.description
    reqData.state_name  = this.form.value.state_name
    reqData.district_name  = this.form.value.district_name
    reqData.city  = this.form.value.city
    reqData.area  = areaString
    
    
    var date1 = new Date(reqData.travel_from);
    var date2 = new Date(reqData.travel_to);
    var Difference_In_Time = date2.getTime() - date1.getTime();
    console.log(Difference_In_Time);
    reqData.total_days  = Math.ceil(Difference_In_Time / (1000 * 3600 * 24));
    
    this.db.presentLoader();
    this.db.postReq(reqData,'travel_plan/add').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.db.successAlert(resp['status'],resp['message']);
        this.db.matdialog.closeAll();
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errHandler(err);
      this.db.errDismissLoader();
    })
    
  }
}
