import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { FormGroup,FormControl, Validators } from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import { DatabaseService } from 'src/app/_services/database.service';
import swal from 'sweetalert';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
@Component({
  selector: 'app-beat-plan-detail-page',
  templateUrl: './beat-plan-detail-page.component.html'
})
export class BeatPlanDetailPageComponent implements OnInit {
  flag : any = '';
  beatData:any = '';

  constructor(public db:DatabaseService,public activatedroute:ActivatedRoute,public dialog: MatDialog,private _location: Location) { }

  ngOnInit() {
    this.getData();
    this.beatPlanData(this.activatedroute.snapshot.params.id);
  }

  beatPlanDetail:any={};
  attendDetail:any={};
  beatYearMonthArray:any=[];
  beatYearMonthDisplayArray:any=[];
  data:any=[];
  filter:any={};
  async getData(){
    await this.db.presentLoader();
    this.filter.beat_plan_month = this.beatYearMonthArray[this.filter.monthIndex]
    this.filter.created_on = '';
    this.db.postReq(this.filter,'beat_plan/detail').subscribe(async resp=>{
      await this.db.dismissLoader();
      this.beatPlanDetail = resp['result'].data;
      console.log("this.beatPlanDetail===>",this.beatPlanDetail)
      if (resp['status'] == 'success') {
        if(!this.beatYearMonthArray.length){
          this.beatYearMonthArray= resp['result'].beatYearMonthArray
          this.beatYearMonthDisplayArray= resp['result'].beatYearMonthDisplayArray;
          this.filter.monthIndex = this.beatYearMonthArray.length-1;
          this.filter.displayMonth = this.beatYearMonthDisplayArray[this.filter.monthIndex]
        }
        
        let year  = parseInt(this.beatYearMonthArray[this.beatYearMonthArray.length-1].split('-')[0]);
        let month = parseInt(this.filter.monthIndex);
                
        let date = new Date(year, month, 1);
        
        let i = 0;
        this.data =[];
        while (date.getMonth() === month) {
          await this.data.push({date:new Date(date)});          
          date.setDate(date.getDate() + 1);
          i++;
        }
        // return false;
        await resp['result'].data.map(async r=>{
          let i = await this.data.findIndex(row =>(( moment(r.attend_date).format('D/M/YYYY') == row.date.toLocaleDateString()) || ( moment(r.attend_date).format('DD/MM/YYYY') == row.date.toLocaleDateString())))
          if(i!=-1)this.data[i].attendData = r
        })
        let day = new Date(year, month, 1).getDay();
        if(day == 1){await this.data.unshift({});}
        if(day == 2){await this.data.unshift({},{});}
        if(day == 3){await this.data.unshift({},{},{});}
        if(day == 4){await this.data.unshift({},{},{},{});}
        if(day == 5){await this.data.unshift({},{},{},{},{});}
        if(day == 6){await this.data.unshift({},{},{},{},{},{});}
        if(day == 7){await this.data.unshift({},{},{},{},{},{},{});}
        
        console.log('data', this.data);
        
        
      }else{
        this.db.presentAlert('Alert',resp['message']);
      }
    },
    (err) => {
      this.db.presentAlert('Alert',err);
    });
  }
  
  prevMonth(){
    if(this.filter.monthIndex == 0) {return;}
    this.filter.monthIndex = this.filter.monthIndex - 1;
    this.filter.displayMonth=this.beatYearMonthDisplayArray[this.filter.monthIndex];
    this.getData()
  }
  nextMonth(){
    if(this.filter.monthIndex == (this.beatYearMonthArray.length-1)){ return;}
    this.filter.monthIndex = this.filter.monthIndex + 1;
    this.filter.displayMonth=this.beatYearMonthDisplayArray[this.filter.monthIndex];
    this.getData()
  }

  backClicked() {
    this._location.back();
  }
  start:any=0;
  limit:any=50;
  totalLength:any;
  beatPlanData(id){
    let reqData = {
      id : id,
      limit : this.limit,
      start : this.start,
      filter : this.filter
    }
    this.db.presentLoader();
    this.db.postReq(reqData,'beat_plan/detail').subscribe(resp=>{
      if(resp['status'] == 'success'){
        this.beatData = resp['result'].data;
        console.log(this.beatData)
        if (resp['result'].data.length) {
          this.flag = true;
        } else {
          this.flag = false;
        }
        // console.log(this.data);
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
  }
  delete(data){
    swal({
      title: "Are you sure?",
      text: "You want to delete this",
      icon: "warning",
      buttons: ["Cancel", "Confirm"],
      dangerMode: true,
    })
    .then((willDelete) => {        
      if (willDelete) {
        this.db.presentLoader();
        
        this.db.postReq(data,'beat/detail/delete').subscribe(resp=>{
          if(resp['status'] == 'success'){
            console.log('data=====>',data);
            this.beatPlanData(this.activatedroute.snapshot.params.id);
            this.getData();
            swal("Deleted", {
              icon: "success",
            });
          }else{
            if(resp['message']=='Invalid token'){
              this.db.sessionExpire();
              return;
            }
            this.db.presentAlert(resp['status'],resp['message'])
          }
        })
        
      } else {
        swal("Your data is safe!");
      }
    });
  }
}
