import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DatabaseService } from 'src/app/_services/database.service';

@Component({
  selector: 'app-product-add',
  templateUrl: './product-add.component.html'
})
export class ProductAddComponent implements OnInit {
  selectedFile: any;

  constructor(private _location: Location, public db: DatabaseService, public actRoute: ActivatedRoute) { }

  ngOnInit() {
    if (this.actRoute.snapshot.params.id) {
      this.getDetail(this.actRoute.snapshot.params.id)
    } else {
      this.formsInit({});
    }
    this.getData()
  }
  data: any = {}
  filteredData: any = {};
  getData() {

    this.db.postReq({ start: 0, limit: 1000, filter: {} }, 'master/product_category').subscribe(resp => {
      if (resp['status'] == 'success') {
        this.data.categoryData = resp['result'].data;
        this.filteredData.categoryData = resp['result'].data
      } else {
        if (resp['message'] == 'Invalid token') {
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'], resp['message'])
      }
    })

    this.db.postReq({ start: 0, limit: 1000, filter: {} }, 'master/brand').subscribe(resp => {
      if (resp['status'] == 'success') {
        this.data.brandData = resp['result'].data;
        this.filteredData.brandData = resp['result'].data
      } else {
        if (resp['message'] == 'Invalid token') {
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'], resp['message'])
      }
    })
    this.db.postReq({ start: 0, limit: 1000, filter: {} }, 'master/product_group').subscribe(resp => {
      if (resp['status'] == 'success') {
        this.data.groupData = resp['result'].data;
        this.filteredData.groupData = resp['result'].data
      } else {
        if (resp['message'] == 'Invalid token') {
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'], resp['message'])
      }
    })

    this.db.postReq({ start: 0, limit: 1000, filter: {} }, 'master/product_size').subscribe(resp => {
      if (resp['status'] == 'success') {
        this.data.sizeData = resp['result'].data;
        this.filteredData.sizeData = resp['result'].data
      } else {
        if (resp['message'] == 'Invalid token') {
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'], resp['message'])
      }
    })

    this.db.postReq({ start: 0, limit: 1000, filter: {} }, 'master/product_type').subscribe(resp => {
      if (resp['status'] == 'success') {
        this.data.divisonData = resp['result'].data;
        this.filteredData.divisonData = resp['result'].data
      } else {
        if (resp['message'] == 'Invalid token') {
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'], resp['message'])
      }
    })

    this.db.postReq({ start: 0, limit: 1000, filter: {} }, 'master/product_series').subscribe(resp => {
      if (resp['status'] == 'success') {
        this.data.seriesList = resp['result'].data;
        this.filteredData.seriesList = resp['result'].data
        console.log("List:----------",this.filteredData.seriesList);
        
      } else {
        if (resp['message'] == 'Invalid token') {
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'], resp['message'])
      }
    })

  }
  // changeListener($event): void {
  //   console.log($event);
  //   this.form.controls['image_path_loading'].setValue(true);

  //   var file = $event.target.files[0];
  //   var reader = new FileReader();
  //   var image
  //   reader.onloadend = function () {
  //     image = reader.result;
  //   }
  //   setTimeout(() => {
  //     console.log(image);
  //     let reqData = { base64: image }
  //     this.db.presentLoader();
  //     this.db.postReq(reqData, 'base64_to_file').subscribe(resp => {
  //       this.db.dismissLoader();
  //       if (resp['status'] == 'success') {
  //         this.form.controls['image_path'].setValue(resp['response'].fileName);
  //         this.form.controls['image_path_loading'].setValue(false);
  //       } else {
  //         if (resp['message'] == 'Invalid token') {
  //           this.db.sessionExpire();
  //           return;
  //         }
  //         this.db.presentAlert(resp['status'], resp['message'])
  //       }
  //     }, err => {
  //       this.db.errDismissLoader();
  //     })
  //   }, 100);

  //   reader.readAsDataURL(file);

  // }


  image_path_loading:any
  // form: FormGroup;
  // changeListener($event): void {
  //   console.log($event);
  //   this.image_path_loading = true;

  //   var file = $event.target.files[0];
  //   var reader = new FileReader();
  //   var image
  //   reader.onloadend = function () {
  //     image = reader.result;
  //   }
  //   setTimeout(() => {
  //     console.log(image);
  //     let reqData = {base64:image}
  //     this.db.presentLoader();
  //     this.db.postReq(reqData,'base64_to_file').subscribe(resp=>{
  //       this.db.dismissLoader();
  //       if(resp['status'] == 'success'){
  //         this.form.controls['image'].setValue(resp['response'].fileName);
  //         this.image_path_loading=false;
  //       }else{
  //         if(resp['message']=='Invalid token'){
  //           this.db.sessionExpire();
  //           return;
  //         }
  //         this.db.presentAlert(resp['status'],resp['message'])
  //       }
  //     },err=>{
  //       this.image_path_loading = false;
  //       this.db.errDismissLoader();
  //     })
  //   }, 100);
    
  //   reader.readAsDataURL(file);
    
  // } 

  changeListener($event): void {
    console.log($event);
    this.image_path_loading = true;
    
    // Set the maximum file size to 5MB (in bytes)
    const maxSize = 0.5 * 1024 * 1024;
    
    var file = $event.target.files[0];
    
    // Validate file size
    if (file.size > maxSize) {
      this.image_path_loading = false;
      // console.log('File size exceeds the 500KB limit');
      this.db.presentAlert('Error', 'File size exceeds the 500KB limit');
      return;
    }
    
    var reader = new FileReader();
    var image;
    
    reader.onloadend = () => {
      image = reader.result;
      console.log(image);
      
      // Prepare the request data
      let reqData = { base64: image };
      
      // Show loader
      this.db.presentLoader();
      
      // Send the request
      this.db.postReq(reqData, 'base64_to_file').subscribe(resp => {
        this.db.dismissLoader();
        if (resp['status'] == 'success') {
          this.form.controls['image'].setValue(resp['response'].fileName);
          this.image_path_loading = false;
        } else {
          if (resp['message'] == 'Invalid token') {
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'], resp['message']);
        }
      }, err => {
        this.image_path_loading = false;
        this.db.errDismissLoader();
      });
    };
    
    reader.readAsDataURL(file);
  }
  
  getDetail(id) {
    this.db.presentLoader();

    this.db.postReq({ 'product_id': id }, 'master/product_detail').subscribe(resp => {
      this.db.dismissLoader();
      if (resp['status'] == 'success') {
        this.formsInit(resp['data']['detail']);

      } else {
        if (resp['message'] == 'Invalid token') {
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'], resp['message'])
      }
    }, err => {
      this.db.errHandler(err);
    })

  }

  



  form: FormGroup;

  formsInit(data: any) {
    this.form = this.db.formBuilder.group({
      image_path: [data.product_image ? data.product_image : null],
      image: [data.product_image ? data.product_image : null, []],
      image_path_loading: [false],
      product_name: [data.product_name ? data.product_name : null, [Validators.required]],
      product_code: [data.product_code ? data.product_code : null, [Validators.required]],
      // product_group: [data.product_group ? data.product_group : null, [Validators.required]],
      size: [data.size ? data.size : null, []],//Validators.required
      sale_price: [data.sale_price ? data.sale_price : null, [Validators.required]],
      product_category: [data.product_category ? data.product_category : null, []],
      product_series: [data.product_series ? data.product_series : null, []],
      sub_category: [data.sub_category ? data.sub_category : null, []],
      // design_paper_code: [data.design_paper_code ? data.design_paper_code : null, [Validators.required]],
      // finish_name: [data.finish_name ? data.finish_name : null, [Validators.required]],
      // finish_short_name: [data.finish_short_name ? data.finish_short_name : null, [Validators.required]],
      // design_paper_name: [data.design_paper_name ? data.design_paper_name : null, [Validators.required]],
      // dealer_point: [data.dealer_point ? data.dealer_point : null ,[]],
      // carpenter_point: [data.carpenter_point ? data.carpenter_point : null ,[]],
      // status: [data.status ? data.status.toString() : '1', [Validators.required]],
      product_id: [data.id ? data.id : null],
      // bill_category_name: [data.bill_cat ? data.bill_cat : null, [Validators.required]],//Validators.required
      // brand: [data.brand ? data.brand : null, [Validators.required]],
    })
  }

  onSubmit() {
    if (this.form.invalid) {
      this.form.markAllAsTouched()
      return
    }
    console.log("FormValues", this.form.value);
    this.db.presentLoader();
    this.db.postReq(this.form.value, this.actRoute.snapshot.params.id ? 'master/update_product' : 'master/add_product').subscribe(resp => {
      this.db.dismissLoader();
      if (resp['status'] == 'success') {
        this.db.successAlert(resp['status'], resp['message']);
        this.backClicked();
      } else {
        if (resp['message'] == 'Invalid token') {
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'], resp['message'])
      }
    }, err => {
      this.db.errDismissLoader();
    })

  }

  backClicked() {
    this._location.back();
  }

}
