import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { PaymentStatusModelComponent } from '../../payment/payment-status-model/payment-status-model.component';
import { MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { DatabaseService } from 'src/app/_services/database.service';

@Component({
  selector: 'app-travel-detail',
  templateUrl: './travel-detail.component.html'
})
export class TravelDetailComponent implements OnInit {
  constructor(public activatedroute:ActivatedRoute,public db:DatabaseService,private _location: Location, public dialog: MatDialog) { }
  ngOnInit() {
    this.getDetail(this.activatedroute.snapshot.params.id)
    
  }
  data:any={};
  getDetail(id){
    this.db.presentLoader();
    
    this.db.postReq({'travel_plan_id':id},'travel_plan/detail').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.data = resp['data'];   
        console.log(this.data);
        
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errHandler(err);
    })
    
  }
  backClicked() {
    this._location.back();
  }
  
  
  openStatusDialog(): void {
    const dialogRef = this.dialog.open(PaymentStatusModelComponent, {
      width: '400px',
      autoFocus: false,
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

}
