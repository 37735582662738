import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material';
import * as moment from 'moment';
import { DatabaseService } from '../_services/database.service';

@Component({
  selector: 'app-followup-model',
  templateUrl: './followup-model.component.html',
})
export class FollowupModelComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data:any,public db:DatabaseService,public formBuilder:FormBuilder){}
  ngOnInit() {
    console.log(this.data);
    
    this.todayDate = moment().format('YYYY-MM-DD')
    this.initForm();
    this.getDrTypes();
    this.getsalesData();
  }
  todayDate:any
  form: FormGroup;  
  initForm() {
    
    this.form = this.formBuilder.group({
      sales_user:["", [Validators.required]],
      followup_date: [this.data.id ? moment().format(this.data.followup_date) : moment().format('YYYY-MM-DD'), [Validators.required]],
      // followup_time: [this.data.id ? this.data.followup_time : '', [Validators.required]],
      dr_type: ["", [Validators.required]],
      dr_id: ["", [Validators.required]],
      followup_id: [this.data.id ? this.data.id : null],
      description: [this.data.id ? this.data.description : null, [Validators.required]],
    });
    
    // this.getData()
  }
  
  drType:any=[];
  getDrTypes(){
    this.db.presentLoader();
    this.db.postReq({},"followup/dr_type").subscribe(async (resp:any) => {
      await this.db.dismissLoader();
      // this.db.requestSent = true;
      if(resp['message']=='Invalid token'){
        this.db.sessionExpire();
        return;
      }
      if(resp['status']=='success'){
        this.drType = resp['result'].data;
        if(this.data.id){
          let i = this.drType.findIndex(r=> r.id== this.data.cust_type_id);
          this.form.controls['dr_type'].setValue(this.drType[i]);
          this.getDrData();
        }
        if(this.data.basicDetail.cust_type_id){
          let i = this.drType.findIndex(r=> r.id== this.data.basicDetail.cust_type_id);
          this.form.controls['dr_type'].setValue(this.drType[i]);
          this.getDrData();
        }
      }else{
        this.db.presentAlert(resp['status'],resp['message']);
      }
    },async err => {
      // this.db.errorAlert(err); 
    })  
  }
  
  drData:any=[];
  filteredDrData:any=[];
  
  getDrData(){
    let reqData =  {
      "limit" :100000,
      "start" : 0,
      "filter" : {
        "cust_type_id" :this.form.value.dr_type.id
      }
    }
    this.db.presentLoader();
    this.db.postReq(reqData,"followup/dr_list").subscribe(async (resp:any) => {
      await this.db.dismissLoader();
      if(resp['message']=='Invalid token'){
        this.db.sessionExpire();
        return;
      }
      // this.db.requestSent = true;
      if(resp['status']=='success'){
        this.drData = resp['result'].data;
        this.filteredDrData= resp['result'].data;
        if(this.data.id){
          let i = this.drData.findIndex(r=> r.id== this.data.customer_id);
          this.form.controls['dr_id'].setValue(this.drData[i]);
        }
        if(this.data.basicDetail.id){
          let i = this.drData.findIndex(r=> r.id== this.data.basicDetail.id);
          this.form.controls['dr_id'].setValue(this.drData[i]);
        }
      }else{
        this.db.presentAlert(resp['status'],resp['message']);
      }
    },async err => {
      // this.db.errorAlert(err); 
    })  
  }
  
  salesData:any=[];
  filteredSalesData:any=[];
  getsalesData(){
    let reqData =  {
      "limit" :100000,
      "start" : 0,
      "filter" : {
        // "cust_type_id" :this.form.value.dr_type.id
      }
    }
    this.db.presentLoader();
    this.db.postReq(reqData,"followup/sales_user").subscribe(async (resp:any) => {
      await this.db.dismissLoader();
      if(resp['message']=='Invalid token'){
        this.db.sessionExpire();
        return;
      }
      // this.db.requestSent = true;
      if(resp['status']=='success'){
        this.salesData = resp['result'].data;
        this.filteredSalesData = resp['result'].data;
        
        if(this.data.id){
          let i = this.salesData.findIndex(r=> r.id== this.data.user_id);
          this.form.controls['sales_user'].setValue(this.salesData[i]);
        }
        
      }else{
        this.db.presentAlert(resp['status'],resp['message']);
      }
    },async err => {
      // this.db.errorAlert(err); 
    })  
  }
  
  onSubmit(){
    if(this.form.invalid){
      this.form.markAllAsTouched()
      return;
    }
    let reqData = this.form.value
    console.log(reqData);
    
    this.db.presentLoader();
    this.db.postReq(reqData,this.data.id ? 'followup/update' : 'followup/add').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.db.successAlert(resp['status'],resp['message']);
        this.db.matdialog.closeAll();
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errHandler(err);
      this.db.errDismissLoader();
    })
    
  }
}
