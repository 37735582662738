import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { DatabaseService } from 'src/app/_services/database.service';
import { DialogService } from 'src/app/_services/dialog.service';
import swal from 'sweetalert';
@Component({
  selector: 'app-user-data-transfer',
  templateUrl: './user-data-transfer.component.html',
})
export class UserDataTransferComponent implements OnInit {

  constructor(public matdialog:MatDialog, @Inject(MAT_DIALOG_DATA) public data :any ,public db :DatabaseService) { }
  
  // ngOnInit() {
  //   this.formsInit();
  // }
  ngOnInit() {
    // console.log(this.data);
    this.getSalesUserList();
    // if(this.data.data.id){
    //   this.formsInit(this.data.data);
    // }    else{
    //   this.formsInit({});
    // }
    this.formsInit({});
  }

  oldSalesUser:any=[];
  newSalesUser:any=[];
  
  getSalesUserList(){
    let reqData = {
      limit : 100000,
      start : 0,
      // filter:{}
      filter : {for_sales_user:1}
    }
    this.db.presentLoader();
    this.db.postReq(reqData,'master/sales_user_list').subscribe(async resp=>{
      if(resp['status'] == 'success'){
        this.oldSalesUser = resp['result'].data;
        this.newSalesUser = resp['result'].data;
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
  }
  getDetail(id){
    this.db.presentLoader();
    
    this.db.postReq({'role_id':id},'master/role_detail').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.formsInit(resp['data']['detail']);
        
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errHandler(err);
    })
    
  }
  form: FormGroup;
  
  formsInit(data:any) {
    console.log(data);
    
    this.form = this.db.formBuilder.group({
      old_sales_user :[data.old_sales_user ? data.old_sales_user : null ,[Validators.required]],
      new_sales_user :[data.new_sales_user ? data.new_sales_user : null ,[Validators.required]],
      
    })
  }
  onSubmit(){    
    if(this.form.invalid){
      this.form.markAllAsTouched();
      return
    }
    swal({
      title: "Are you sure?",
      text:  "You want to transfer data!",
      icon: "warning",
      buttons: ["Cancel", "Confirm"],
      dangerMode: true,
    })
    .then((willDelete) => {        
      if (willDelete) {
        console.log('--->',this.form.value);
        this.db.presentLoader();
        this.db.postReq(this.form.value,'master/sales_user/data_transfer').subscribe(resp=>{
          this.db.dismissLoader();
          if(resp['status'] == 'success'){
            this.db.successAlert(resp['status'],resp['message']);
            this.matdialog.closeAll()        
          }else{
            if(resp['message']=='Invalid token'){
              this.db.sessionExpire();
              return;
            }
            this.db.presentAlert(resp['status'],resp['message'])
          }
        },err=>{
          this.db.errDismissLoader();
        })
        
      } else {
        swal("Your data is safe!");
      }
    });
    
    
  }

}
