import { NgModule } from '@angular/core';

import 
{ 
    MatButtonModule, 
    MatInputModule, 
    MatIconModule,
    MatSelectModule,
    MatFormFieldModule,
    MatRadioModule, 
    MatTooltipModule,
    MatDialogModule,
    MatCheckboxModule,
    MatMenuModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRippleModule,
    MatExpansionModule,
    MatSliderModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    MatBottomSheetModule,
    MatDividerModule
    
}  from '@angular/material';
import { MatSelectFilterModule } from 'mat-select-filter';

@NgModule({
    declarations: [
        
    ],
    imports: 
    [
        MatSelectFilterModule,
        MatButtonModule,
        MatInputModule,
        MatIconModule,
        MatSelectModule,
        MatFormFieldModule,
        MatRadioModule,
        MatTooltipModule,
        MatDialogModule,
        MatCheckboxModule,
        MatMenuModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatRippleModule,
        MatExpansionModule,
        MatSliderModule,
        MatButtonToggleModule,
        MatSlideToggleModule,
        MatButtonToggleModule,
        MatBottomSheetModule,
        MatDividerModule
    ],
    
    exports:
    [
        MatSelectFilterModule,
        MatButtonModule,
        MatInputModule,
        MatIconModule,
        MatSelectModule,
        MatFormFieldModule,
        MatRadioModule,
        MatTooltipModule,
        MatDialogModule,
        MatCheckboxModule,
        MatMenuModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatRippleModule,
        MatExpansionModule,
        MatSliderModule,
        MatButtonToggleModule,
        MatSlideToggleModule,
        MatBottomSheetModule,
        MatDividerModule,
    ],
})



export class  MaterialModule { }