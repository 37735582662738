import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import {MatDialog} from '@angular/material/dialog';
// import { ProductUnitDetailModelComponent } from '../product-unit-detail-model/product-unit-detail-model.component';
import { DatabaseService } from 'src/app/_services/database.service';
import * as moment from 'moment';
import swal from 'sweetalert';
import { ChatDetailModelComponent } from '../detail/detail-model.component';
// import { ProductUnitDetailModelComponent } from '../product-unit-detail-model/product-unit-detail-model.component';


@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
})
export class FeedBackListComponent implements OnInit {
  
  data:any=[];
  dataNotFound=false
  reqSent=false
  autoFocus?: boolean = true;
  
  constructor(
    private _location: Location,
    public dialog: MatDialog,
    public db:DatabaseService
    ) { }
    ngOnInit() {
      this.todayDate =  moment(new Date ()).format('YYYY-MM-DD')
      this.getData();
    }
    todayDate :any
    pageNo:any=1;
    tempPageNo =1;;
    totalPages:any
    start:any=0;
    limit:any=50;
    totalLength:any;
    filter:any={};
    getData(){
      this.dataNotFound = false;
      this.reqSent = false;
      this.filter.type=this.tabActive;
      let reqData = {
        limit : this.limit,
        start : this.start,
        filter : this.filter
      }
      
      this.db.presentLoader();
      this.db.postReq(reqData,'feedback/list').subscribe(resp=>{
        this.reqSent = true;
        this.getCounters();
        if(resp['status'] == 'success'){
          this.data = resp['result'].data;
          if(!this.data.length) this.dataNotFound = true;
          this.totalLength = resp['result'].totalCount;
          this.totalPages = Math.ceil(this.totalLength / this.limit);
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        this.db.errHandler(err);
      })
    }
    counters:any={};
    getCounters(){
      
      this.db.presentLoader();
      this.db.postReq({},'feedback/count').subscribe(resp=>{
        this.reqSent = true;
        if(resp['status'] == 'success'){
          resp['result'].data.map(r=>{
            this.counters[r.type] = r.count;
          })
          console.log(this.counters);
          
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        this.db.errHandler(err);
      })
    }
    pagination(action){
      
      if(action == 'pageNo'){
        if(this.pageNo > 0 && this.pageNo <=this.totalPages){
          this.start = this.limit*(this.pageNo-1)
        }else{
          this.pageNo = Math.ceil(this.totalLength / this.data.length);
        }
      }
      else if(action=='next'){
        
               if(this.totalLength == (this.start+this.data.length)) return;

        this.start = this.start+this.limit
        this.pageNo ++ ;
      }else{
        if(this.pageNo == 1) return
        this.start = this.start-this.limit
        this.pageNo -- ;
      }
      this.getData();
      this.tempPageNo = this.pageNo
    }
    refresh(){
      this.start = 0;
      this.limit = 50;
      this.filter = {};
      this.getData();
    }
    
    backClicked() {
      this._location.back();
    }  
    
    revert(row): void {
      console.log(row);
      
      const dialogRef = this.dialog.open(ChatDetailModelComponent, {
        width: '768px',
        autoFocus: false,
        data: row
      });
      
      dialogRef.afterClosed().subscribe(result => {
        this.getData();
      });
    }
    tabActive:any='unread';
  }
  