import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, Validators } from '@angular/forms';
import { DatabaseService } from 'src/app/_services/database.service';
import swal from 'sweetalert';
import { CustomEventsService } from 'src/app/_services/custom-events.service';
import * as moment from 'moment';
// import { type } from 'os';

@Component({
  selector: 'app-task-add',
  templateUrl: './task-add.component.html',
  // styleUrls: ['./dr-add.component.scss']
})
export class TaskAddComponent implements OnInit {
  type:any = this.activatedroute.snapshot.params.type
  typeName:any = this.type == 1 ? 'Distributor' : 'Customer'
  
  constructor(public events:CustomEventsService,public activatedroute:ActivatedRoute ,private _location: Location,public db:DatabaseService) { }
  
  ngOnInit() {
    this.getDrType()
    this.getSourceList();
    
    this.db.getStates();
    if(this.activatedroute.snapshot.params.id){
      this.getDetail(this.activatedroute.snapshot.params.id)
    }    else{
      this.formsInit({});
    }  }
    getDetail(id){
      this.db.presentLoader();
      
      this.db.postReq({'customer_id':id},'dis_network/detail').subscribe(resp=>{
        this.db.dismissLoader();
        if(resp['status'] == 'success'){
          
          this.formsInit(resp['data']);
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        this.db.errHandler(err);
      })
      
      // this.db.postReq({'hsn_id':id},'master/hsn_detail').subscribe(resp=>{
      //   this.db.dismissLoader();
      //   if(resp['status'] == 'success'){
      //     this.formsInit(resp['data']['detail']);
      
      //   }else{
      //     if(resp['message']=='Invalid token'){
      //       this.db.sessionExpire();
      //       return;
      //     }
      //     this.db.presentAlert(resp['status'],resp['message'])
      //   }
      // },err=>{
      //   this.db.errHandler(err);
      // })
      
    }
    getDrType(){
      this.db.postReq({},'dis_network/type_list').subscribe(resp=>{
        this.db.drType = resp['result'].data
        this.db.drType.map(r=>r.id = r.id.toString())      
      })
    }
    sources:any=[];
    filteredsources:any=[];
    getSourceList(){
      this.db.postReq({},'dis_network/source_list').subscribe(resp=>{
        this.sources = resp['result'].data
        this.filteredsources = resp['result'].data
      })
    }
    
    form: FormGroup;
    banks:any=[];
    filteredbanks:any=[]
    formsInit(data) {
      
      this.db.http.get("assets/banks.json").subscribe(data =>{
        this.banks = data;
        this.filteredbanks = data
      })
      
      
      this.form = this.db.formBuilder.group({
        task : [this.activatedroute.snapshot.params.id ? data.basicDetail.task : null   , [Validators.required]],
        priority : [this.activatedroute.snapshot.params.id ? data.basicDetail.priority : null   , [Validators.required]],
        remark : [this.activatedroute.snapshot.params.id ? data.basicDetail.remark : null   , [Validators.required]],
        cust_type_id : [this.activatedroute.snapshot.params.id ? data.basicDetail.cust_type_id : null   , []],
        cust_id : [this.activatedroute.snapshot.params.id ? this.activatedroute.snapshot.params.id : null],
        customer_encrypt_id:[ this.activatedroute.snapshot.params.id ? this.activatedroute.snapshot.params.id : null, []],
        assignedUser: [[] , []],
      })
      this.getUsers(data);
      
      // this.db.getStates();
      // this.db.getArea(this.form.value.state_name,this.form.value.district_name,this.form.value.city);
      // this.db.getBeat(this.form.value.state_name,this.form.value.district_name,this.form.value.city,this.form.value.area);

      if(!this.activatedroute.snapshot.params.id){ this.form.controls['cust_type_id'].setValue(this.type)} 
      
      
      
    }
    
    
    onSubmit(){
      console.log(this.form);
      if(this.form.invalid){
        this.form.markAllAsTouched();
        console.log(this.form.value)
        return
      }
      let reqData:any = {};
      // this.form.value.date_of_birth = this.form.value.date_of_birth == 'Invalid date' ? '0000-00-00' : this.form.value.date_of_birth;
      // this.form.value.date_of_anniversary = this.form.value.date_of_anniversary == 'Invalid date' ? '0000-00-00' : this.form.value.date_of_anniversary;
      reqData.basicDetail = this.form.value
      // reqData.basicDetail.cust_type = this.db.drType.filter(r=>r.id == reqData.basicDetail.cust_type_id)[0].name
      console.log(reqData);
      reqData.status = this.form.value.status
      console.log('reqData -->',reqData);
      // return false;
      reqData.basicDetail.is_lead = 1;
      this.db.presentLoader();
      this.db.postReq(reqData,this.activatedroute.snapshot.params.id  ? 'dis_network/update_network' : 'dis_network/add_task').subscribe(resp=>{
        this.db.dismissLoader();
        if(resp['status'] == 'success'){
          this.db.successAlert(resp['status'],resp['message']);
          this.backClicked();
          this.db.router.navigateByUrl('task-list/'+this.form.value.cust_type_id,{replaceUrl:true})
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        this.db.errDismissLoader();
      })
      
    }
    
    backClicked() {
      this._location.back();
    }
    
    usersData:any=[]
    filteredUsersData:any=[]
    getUsers(data:any={}){
      let reqData = {
        limit : 30000,
        start : 0,
        filter : {}
      }
      this.db.presentLoader();
      this.db.postReq(reqData,'dis_network/sales_user').subscribe(resp=>{
        if(resp['status'] == 'success'){
          this.usersData = resp['result'].data;
          this.filteredUsersData = this.usersData

          console.log(this.usersData);
          if(data.salesUserDetail){
            data.salesUserDetail.map(r=>{
              let i = this.usersData.findIndex(item => item.id == r.user_id)
              if(i!= -1){
                this.form.controls['assignedUser'].setValue([...this.form.value.assignedUser,this.usersData[i]]);
              }
            })
          }
          

        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        
      })
    }
  }
  