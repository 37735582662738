import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-invoice-model',
  templateUrl: './invoice-model.component.html'
})
export class InvoiceModelComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
