import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { DatabaseService } from 'src/app/_services/database.service';
import { MatSelectModule } from '@angular/material';
import swal from 'sweetalert';
// import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import * as moment from 'moment'
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-quotation-add',
  templateUrl: './quotation-add.component.html'
})
export class QuotationAddComponent implements OnInit {

  data:any=[];
  dataNotFound=false
  reqSent=false;
  orderType:any;
  constructor(private _location: Location,public db:DatabaseService,public actRoute:ActivatedRoute) { 
    // if(localStorage.getItem('loginType') == 'user'){this.getCust('');}
    
    // this.getProducts('');
    this.getOrderNo();
    this.form.orderItem=[];
    this.form.basicData={
      net_amt:null,
      total_qty:null,
      total_amt:null,
      tax_amt:null,
      discount_amt:null,
      orderItem:null,
      customer_id:null,
      order_no:null,
      order_date:moment(new Date ()).format('YYYY-MM-DD'),
      delivery_date:moment(new Date ()).format('YYYY-MM-DD'),
      payment_date:moment(new Date ()).format('YYYY-MM-DD')
    };
    this.form.product_detail={
      stock_qty:null,
      order_qty:null,
      rate:null,
      discount:null,
      discount_amt:null,
      tax:null,
      tax_amt:null,
      total_amt:null,
      sub_total:null
    };
    if(localStorage.getItem('loginType') == 'dealer'){this.form.basicData.customer_id= JSON.parse(localStorage.getItem('sfaUserData')).id}
    
    
    this.orderType = this.actRoute.snapshot.params.type;
    console.log('orderType --->', this.orderType);
    
    if(this.orderType == 'primary'){
      this.orderType = 'Primary';
    }
    
    if(this.orderType == 'secondary'){
      this.orderType = 'Secondary';
    }
    
    if(this.actRoute.snapshot.params.id){
      this.editHandler(this.actRoute.snapshot.params.id)
    }
  }
  
  disable:any= false;
  secondaryDrType:any = [];
  form:any={};
  ngOnInit() {
    this.data = new Array(7);
    // this.getDrTypes();
    this.getData()
    // this.getSeries('');
    // this.getCust('');
    if (this.actRoute.snapshot.params.type == 'secondary') {

      this.drType = [
        {id : 1, name : "Distributor", slug : "distributor"},
        {id : 2, name : "Dealer", slug : "dealer"},
        {id : 3, name : "Builder", slug : "builder"},
        {id : 4, name : "Architect", slug : "architect"},
        {id : 5, name : "Other", slug : "other"},
      ]
      this.getCust(this.form.dr_type);
      console.log('this.drType',this.drType, 'form --->', this.form);
      
    } else {
      this.drType = [
        {id : 1, name : "Distributor", slug : "distributor"},
        {id : 2, name : "Dealer", slug : "dealer"},
        {id : 3, name : "Builder", slug : "builder"},
        {id : 4, name : "Architect", slug : "architect"},
        {id : 5, name : "Other", slug : "other"},
      ]
      console.log('this.secondaryDrType',this.secondaryDrType);
      // this.getDrTypes();
    }
    
  }
  
  backClicked() {
    this._location.back();
  }
  
  
  
  filter:any={};
  custData:any=[]
  filteredCustData:any=[];
  showLoading:any=false;
  getCust(data){
    console.log('data -->',data);
    
    console.log('form --->', this.form);
    
    console.log('filter.company_name --->', this.filter.company_name);
    
    
    
    this.showLoading = true;
    let reqData = {
      limit : 100000,
      start : 0,
      filter : {company_name:this.filter.company_name,cust_type_id:data}
    }
    
    this.db.presentLoader();
    this.db.postReq(reqData,'order/dr_list').subscribe(resp=>{
      if(resp['status'] == 'success'){
        this.custData = resp['result'].data;
        this.filteredCustData = resp['result'].data;
        console.log('custData -->', this.custData);
        this.showLoading = false;
        // console.log('filteredCustData -->', this.filteredCustData);
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
  }
  
  
  custDealerData:any=[];
  getCustomerDealer(customer_id){
    console.log('data -->',customer_id);
    console.log('form --->', this.form);
    
    console.log('filter.company_name --->', this.filter.company_name);
    
    
    let reqData = {
      limit : 100000,
      start : 0,
      customer_id : customer_id ? customer_id : 0,
      filter : {customer_id:customer_id}
    }
    
    this.db.presentLoader();
    this.db.postReq(reqData,'order/dr_dealer_list').subscribe(resp=>{
      if(resp['status'] == 'success'){
        this.custDealerData = resp['result'].data;
        console.log('custDealerData -->', this.custDealerData);
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
  }
  
  productsData:any=[]
  filteredProductsData:any=[]
  getProducts(product_category){
    let reqData = {
      limit : 100000,
      start : 0,
      filter : {product_name:this.filter.product_name, product_category:product_category,product_code:this.form.product_id}
    }
    this.db.presentLoader();
    this.db.postReq(reqData,'order/product_list').subscribe(async resp=>{
      if(resp['status'] == 'success'){
        this.productsData = resp['result'].data;
        this.filteredProductsData = resp['result'].data;
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
  }
  drType:any=[];
  getDrTypes(){
    this.db.presentLoader();
    this.db.postReq({},"followup/dr_type").subscribe(async (resp:any) => {
      await this.db.dismissLoader();
      // this.db.requestSent = true;
      if(resp['message']=='Invalid token'){
        this.db.sessionExpire();
        return;
      }
      if(resp['status']=='success'){
        this.drType = resp['result'].data;
        console.log('this.drType',this.drType);
        
      }else{
        this.db.presentAlert(resp['status'],resp['message']);
      }
    })  
  }
  
  
  drData:any=[];
  filteredDrData:any=[];
  
  getDrData(cust_type){
    console.log(cust_type)
    let reqData =  {
      "limit" :100000,
      "start" : 0,
      "filter" : {
        "cust_type_id" :cust_type.id
      }
    }
    this.db.presentLoader();
    this.db.postReq(reqData,"followup/dr_list").subscribe(async (resp:any) => {
      await this.db.dismissLoader();
      if(resp['message']=='Invalid token'){
        this.db.sessionExpire();
        return;
      }
      if(resp['status']=='success'){
        this.drData = resp['result'].data;
        this.filteredDrData= resp['result'].data;
      }else{
        this.db.presentAlert(resp['status'],resp['message']);
      }
    },async err => {
      // this.db.errorAlert(err); 
    })  
  }
  getOrderNo(){
    let reqData = {
      
    }
    this.db.presentLoader();
    this.db.postReq(reqData,'quotation/get_quotation_no').subscribe(resp=>{
      if(resp['status'] == 'success'){
        this.form.basicData.quotation_no = resp['result'].data.quotation_no;
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
  }
  paymentDateHandler(){    
    let index = this.custData.findIndex(r=>(r.id === this.form.basicData.customer_id))
    if(index!=-1){
      this.form.basicData.payment_date = moment(new Date()).add(this.custData[index].credit_day, 'days').format("YYYY-MM-DD");
    }
  }
  
  calc(){
    
    console.log('calc fn called');
    console.log('this.form.product_detail1 -->',this.form.product_detail);
    let reqData = {
      limit : 100000,
      start : 0,
      filter : {product_name:this.filter.product_name,product_id:this.form.product_detail.product_id, product_category:this.form.product_category,product_code:this.form.product_id}
    }
    
    if(this.form.product_detail.product_id && this.form.basicData.customer_id){
      this.db.postReq(reqData,'order/product_list').subscribe(resp=>{
        let product_detail = this.productsData.filter(r=>r.id===this.form.product_detail.product_id)[0]
        let dr_disc_data;
        
        if(resp['result'].data.length){
          // resp['result'].data[0].min_qty <= this.form.product_detail.order_qty
          dr_disc_data = resp['result'].data[0];
          this.form.product_detail.discount = dr_disc_data.discount;
        }

        console.log("this.form.product_detail",this.form.product_detail)
        
        this.form.unit = product_detail.unit;
        var sanitizedSalePrice = product_detail.sale_price;
        this.form.product_detail.rate = parseInt(sanitizedSalePrice);
        // console.log("saleProce",product_detail.sale_price)
        // console.log("sale_price_type",typeof(product_detail.sale_price))
        this.form.product_detail.stock_qty = product_detail.stock_qty;
        this.form.product_detail.product_name = product_detail.product_name;
        this.form.product_detail.product_group = product_detail.product_group;
        this.form.product_detail.product_code = product_detail.product_code;
        this.form.product_detail.customer_id = this.form.customer_id;
        this.form.product_detail.product_category = this.form.product_category;
        this.form.product_detail.product_series = this.form.product_series;
        this.form.product_detail.unit = this.form.unit;
        this.form.product_detail.product_series = this.form.product_series;
        this.form.product_detail.dispatch_qty = 0;
        this.form.product_detail.pending_qty = parseInt(this.form.product_detail.order_qty);
        
        this.form.product_detail.tax = 18;
        // this.form.product_detail.tax = parseFloat(product_detail.tax_percentage);  
        
        this.form.product_detail.net_amt =  (this.form.product_detail.rate * this.form.product_detail.order_qty);
        // this.form.product_detail.discount_amt = ((this.form.product_detail.net_amt / 100 ) * this.form.product_detail.discount);  
        this.form.product_detail.sub_total = this.form.product_detail.net_amt ;
        this.form.product_detail.tax_amt =  (this.form.product_detail.net_amt  / 100) * this.form.product_detail.tax ; 
        // this.form.product_detail.tax_amt =  ((this.form.product_detail.net_amt  / 100) *  this.form.product_detail.tax)
        this.form.product_detail.total_amt = this.form.product_detail.net_amt  + this.form.product_detail.tax_amt
        this.form.product_detail.discount_amt = 0;
        this.form.product_detail.net_amt = parseFloat(this.form.product_detail.net_amt).toFixed(2)
        this.form.product_detail.sub_total = parseFloat(this.form.product_detail.sub_total).toFixed(2)
        // this.form.product_detail.discount_amt = parseFloat(this.form.product_detail.discount_amt).toFixed(2)
        console.log("discountAMT",this.form.product_detail.discount_amt )
        this.form.product_detail.tax_amt = parseFloat(this.form.product_detail.tax_amt).toFixed(2)
        this.form.product_detail.total_amt = parseFloat((this.form.product_detail.total_amt).toFixed(2) + (this.form.product_detail.tax_amt).toFixed(2));
        
        // console.log("mydata",{"net_amt":this.form.product_detail.net_amt,"subTotal":this.form.product_detail.sub_total,"discount":this.form.product_detail.discount_amt})
        // console.log('this.form.product_detail -->',this.form.product_detail);
      })
      
    }else{
      this.form.product_detail={
        stock_qty:null,
        order_qty:null,
        rate:null,
        discount:null,
        discount_amt:0,
        tax:null,
        tax_amt:null,
        total_amt:null,
        sub_total:null
      };
    }
  }
  
  
  // checkSameTypeCategory(){
  //   if(this.form.orderItem.length){
      
  //     var tempIndex = this.form.orderItem.findIndex(x=>x.product_category == this.form.product_category);
  //     console.log('tempIndex', tempIndex);
  //     if(tempIndex === -1 ){
  //       this.db.presentAlert('Error','Please add order of same category type!');
  //       return;
  //     }
      
  //   }
  // }
  
  
  addToList(){
    console.log(this.form)
    
    
    
    if(!this.form.product_detail.product_id){
      this.db.presentAlert('Error','Please select product first!');
      return;
    }
    
    if(!this.form.product_detail.order_qty || this.form.product_detail.order_qty < 0){
      this.db.presentAlert('Error','Please enter a valid quantity!');
      return;
    }
    
    if(this.form.orderItem.length){
      
      var tempIndex = this.form.orderItem.findIndex(x=>x.product_category == this.form.product_category);
      console.log('tempIndex', tempIndex);
      // if(tempIndex === -1 ){
      //   this.db.presentAlert('Error','Please add order of same category type!');
      //   return;
      // }
      
    }
    
    
    let i = this.form.orderItem.findIndex(r=>r.product_id === this.form.product_detail.product_id)
    if(i != -1){
      this.form.orderItem.splice(i,1)
    }
    
    this.form.orderItem.push(this.form.product_detail);
    
    
    this.form.basicData.net_amt = 0;
    this.form.basicData.total_qty = 0;
    this.form.basicData.total_amt = 0;
    this.form.basicData.tax_amt = 0;
    this.form.basicData.discount_amt = 0;
    this.form.basicData.sub_total = 0;
    this.form.orderItem.map(r=>{
      this.form.basicData.total_qty = parseFloat(this.form.basicData.total_qty) + parseInt(r.order_qty)
      this.form.basicData.net_amt = parseFloat(this.form.basicData.net_amt) + parseFloat(r.net_amt)
      this.form.basicData.total_amt = parseFloat(this.form.basicData.total_amt) + parseFloat(r.total_amt)
      this.form.basicData.tax_amt = parseFloat(this.form.basicData.tax_amt) + parseFloat(r.tax_amt)
      this.form.basicData.discount_amt = parseFloat(this.form.basicData.discount_amt) + parseFloat(r.discount_amt)
      this.form.basicData.sub_total = parseFloat(this.form.basicData.sub_total) + parseFloat(r.sub_total)
      
      this.form.basicData.net_amt = parseFloat(this.form.basicData.net_amt).toFixed(2)
      this.form.basicData.total_amt = parseFloat(this.form.basicData.total_amt).toFixed(2)
      this.form.basicData.tax_amt = parseFloat(this.form.basicData.tax_amt).toFixed(2)
      this.form.basicData.discount_amt = parseFloat(this.form.basicData.discount_amt).toFixed(2)
      this.form.basicData.sub_total = parseFloat(this.form.basicData.sub_total).toFixed(2)
      r.customer_id = this.form.basicData.customer_id;
      
      
    });
    this.form.basicData.total_item = this.form.orderItem.length;
    
    this.form.product_detail={
      stock_qty:null,
      order_qty:null,
      rate:null,
      discount:null,
      discount_amt:null,
      tax:null,
      tax_amt:null,
      total_amt:null,
      sub_total:null,
    };
  }
  onSubmit(data:any){
    this.form.orderType = this.actRoute.snapshot.params.type == 'primary' ? 'Primary' : 'Secondary';
    let reqData = this.form
    // console.log(reqData);return
    if(this.orderEditId){
      console.log(reqData);
      reqData.order_id = this.orderEditId;
    }
    
    if(this.custData.length){
      var custDataTemp = this.custData.filter(x=> x.id == this.form.basicData.customer_id)[0];
      console.log('custDataTemp',custDataTemp);
      this.form.basicData.customer_name = custDataTemp.company_name
      this.form.basicData.customer_type = custDataTemp.cust_type
    }

    if(this.custDealerData.length){
      var custDealerData = this.custDealerData.filter(x=> x.id == this.form.basicData.customer_dealer_id)[0];
      console.log('custDealerData',custDealerData);
      if(custDealerData != undefined){
        this.form.basicData.customer_dealer_name = custDealerData.company_name
        this.form.basicData.customer_dealer_mobile = custDealerData.mobile
        this.form.basicData.customer_dealer_type = custDealerData.cust_type
        this.form.basicData.customer_dealer_code = custDealerData.cust_code
      }
    }
    

    this.db.presentLoader();
    this.db.postReq(reqData,this.orderEditId ? "order/update" :'quotation/add').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.db.successAlert(resp['status'],resp['message']);
        this.backClicked();
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errDismissLoader();
    })
    
  }
  removeItem(i){
    
    swal({
      title: "Are you sure?",
      text: "You want remove this item!" ,
      icon: "warning",
      buttons: ["Cancel", "Confirm"],
      dangerMode: true,
    })
    .then((willDelete) => {        
      if (willDelete) {
        this.form.orderItem.splice(i,1)
        
      } else {
        swal("Your data is safe!");
      }
    });
    
    
  }
  orderEditId:any
  editHandler(orderId){
    this.orderEditId = orderId
    this.db.presentLoader();
    
    this.db.postReq({'order_id':orderId},'order/detail').subscribe(async resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        let orderData = resp['result'];   
        console.log(orderData);
        this.orderType = orderData.data.order_type;
        this.form.dr_type = parseInt(orderData.data.cust_type_id);
        this.form.basicData = orderData.data;
        this.form.orderItem = orderData.orderItem;
        this.form.basicData.order_date = moment(this.form.basicData.order_date).format('YYYY-MM-DD'),
        this.form.basicData.delivery_date = moment(this.form.basicData.delivery_date).format('YYYY-MM-DD'),
        this.form.basicData.payment_date = moment(this.form.basicData.payment_date).format('YYYY-MM-DD'),
        // this.form.product_series = orderData.orderItem.series;
        
        orderData.orderItem.map(r=>{
          this.form.product_category = r.product_category
          this.form.product_series = r.product_series;
          this.form.product_detail.product_id = r.product_id
          this.getProducts(r.product_series);
        })
        await this.drType.map(r=>{
          if (orderData.data.cust_type_id == r.id) {
            this.form.dr_type = r.id
          } 
        });
        

        if (this.form.basicData.order_type == 'Secondary') {
          this.drType = [{id : 10, name : "Retailer", slug : "retailer"}];
          // this.form.dr_type = {id : 10, name : "Retailer", slug : "retailer"};
          console.log('this.drType',this.drType, 'form --->', this.form);
        } else {
          this.drType = [
            {id : 1, name : "Distributor", slug : "distributor"},
            {id : 2, name : "Dealer", slug : "dealer"},
            {id : 16, name : "Galleries", slug : "galleries"},
          ]
          console.log('this.drType',this.drType);
        }


        this.getCust(orderData.data.cust_type_id);
        if(this.form.basicData.order_type == 'Secondary'){
          this.getCustomerDealer(orderData.data.customer_id);
        }
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errHandler(err);
    })
    
  }
  filteredData:any={};

  getData(){
    
    this.db.postReq({start:0,limit:1000,filter:{}},'master/product_category').subscribe(resp=>{
      if(resp['status'] == 'success'){
        this.data.categoryData = resp['result'].data        ;
        this.filteredData.categoryData = resp['result'].data        
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    }) 
  }

}