import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { FormGroup,FormControl, Validators } from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import { DatabaseService } from 'src/app/_services/database.service';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import swal from 'sweetalert';
import { BeatPlanDetailModelComponent } from '../beat-plan-detail-model/beat-plan-detail-model.component';

@Component({
  selector: 'app-beat-plan-add',
  templateUrl: './beat-plan-add.component.html',
})
export class BeatPlanAddComponent implements OnInit {
  
  beatData:any = {};
  constructor(public db:DatabaseService,public activatedroute:ActivatedRoute,public dialog: MatDialog,private _location: Location) { }
  
  todayDate:any;
  ngOnInit() {
    this.todayDate =  moment(new Date ()).format('YYYY-MM-DD')
    
    if(this.activatedroute.snapshot.params.id){
      this.filter.beat_plan_id = this.activatedroute.snapshot.params.id
      this.getData()
      this.getUsers();
    }else{
      this.getData();
      this.getUsers();
    }
  }
  
  usersData:any=[]
  filteredUsersData:any=[]
  getUsers(){
    let reqData = {
      limit : 30000,
      start : 0,
      filter : {}
    }
    this.db.presentLoader();
    this.db.postReq(reqData,'dis_network/sales_user').subscribe(resp=>{
      if(resp['status'] == 'success'){
        this.usersData = resp['result'].data;
        this.filteredUsersData = this.usersData
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
  }
  
  
  beatPlanDetail:any={};
  attendDetail:any={};
  beatYearMonthArray:any=[];
  beatYearMonthDisplayArray:any=[];
  data:any=[];
  filter:any={
    beat_detail : {}
  };
  
  refresh(){
    this.filter.beat_detail = {}
    this.getData();
  }
  
  async getData(){
    console.log('getData --> data',this.filter);
    await this.db.presentLoader();
    this.filter.beat_plan_month = this.beatYearMonthArray[this.filter.monthIndex]
    this.filter.created_on = '';
    this.db.postReq(this.filter,'beat_plan/detail').subscribe(async resp=>{
      await this.db.dismissLoader();
      this.beatPlanDetail = resp['result'].data;
      if (resp['status'] == 'success') {
        if(!this.beatYearMonthArray.length){
          this.beatYearMonthArray= resp['result'].beatYearMonthArray
          this.beatYearMonthDisplayArray= resp['result'].beatYearMonthDisplayArray;
          this.filter.monthIndex = this.beatYearMonthArray.length-1;
          this.filter.displayMonth = this.beatYearMonthDisplayArray[this.filter.monthIndex]
        }
        
        let year  = parseInt(this.beatYearMonthArray[this.beatYearMonthArray.length-1].split('-')[0]);
        let month = parseInt(this.filter.monthIndex);
        
        let date = new Date(year, month, 1);
        
        let i = 0;
        this.data =[];
        while (date.getMonth() === month) {
          await this.data.push({date:new Date(date)});          
          date.setDate(date.getDate() + 1);
          i++;
        }
        console.log('data 1 ==>', this.data);
        // return false;
        
        await this.beatPlanDetail.map(async r=>{
          let i = await this.data.findIndex(row =>(( moment(r.beat_date).format('D/M/YYYY') == row.date.toLocaleDateString()) || ( moment(r.beat_date).format('DD/MM/YYYY') == row.date.toLocaleDateString())))
          if(i!=-1){
            this.data[i].class = 'sunday'
          }
        });
        
        console.log('data 1 ==>', this.data);
        console.log('beatPlanDetail -->', this.beatPlanDetail);
        
        let day = new Date(year, month, 1).getDay(); 
        if(day == 1){await this.data.unshift({});}
        if(day == 2){await this.data.unshift({},{});}
        if(day == 3){await this.data.unshift({},{},{});}
        if(day == 4){await this.data.unshift({},{},{},{});}
        if(day == 5){await this.data.unshift({},{},{},{},{});}
        if(day == 6){await this.data.unshift({},{},{},{},{},{});}
        if(day == 7){await this.data.unshift({},{},{},{},{},{},{});}
        
        console.log('data', this.data);
        
        
      }else{
        this.db.presentAlert('Alert',resp['message']);
      }
    },
    (err) => {
      this.db.presentAlert('Alert',err);
    });
  }
  
  prevMonth(){
    if(this.filter.monthIndex == 0) {return;}
    this.filter.monthIndex = this.filter.monthIndex - 1;
    this.filter.displayMonth=this.beatYearMonthDisplayArray[this.filter.monthIndex];
    this.getData()
  }
  nextMonth(){
    if(this.filter.monthIndex == (this.beatYearMonthArray.length-1)){ return;}
    this.filter.monthIndex = this.filter.monthIndex + 1;
    this.filter.displayMonth=this.beatYearMonthDisplayArray[this.filter.monthIndex];
    this.getData()
  }
  
  form:any={};
  openDetail(date:any) {
    console.log('this.beat_form', this.form, 'date --->', date);
    this.filter.beat_plan_id = this.activatedroute.snapshot.params.id
    date = moment(date).format('YYYY-MM-DD')
    this.form.beat_plan_date = date;
    if(this.activatedroute.snapshot.params.id){
      // console.log('usersData',this.usersData);
      var user_id = this.beatPlanDetail[0].user_id;
    }else{
      var user_id = this.form.user_id;
    }
    
    console.log('userId', user_id);
    var userData = this.usersData.filter(y=>y.id == user_id)[0];
    console.log('userData',userData);
    this.form.emp_code = userData.emp_code ? userData.emp_code : '';
    this.form.user_id = userData.id;
    this.form.user_role = userData.role;
    this.form.name = userData.name ? userData.name : '';
    this.form.role_name = userData.role_name ? userData.role_name : '';
    
    if (!this.form.user_id) {
      this.db.presentAlert('Error','Please select user!');
    } else {
      const dialogRef = this.dialog.open(BeatPlanDetailModelComponent, {
        width: '550px',
        autoFocus: false,
        data: this.form
      });
      
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        this.getData();
      });
    }
    
  }
  
  deleteBeat(data){
    console.log(data);
    swal({
      title: "Are you sure?",
      text: "You want to delete this",
      icon: "warning",
      buttons: ["Cancel", "Confirm"],
      dangerMode: true,
    })
    .then((willDelete) => {        
      if (willDelete) {
        this.db.presentLoader();
        
        this.db.postReq(data,'beat_plan/delete_beat').subscribe(resp=>{
          if(resp['status'] == 'success'){
            this.getData();
            swal("Deleted", {
              icon: "success",
            });
          }else{
            if(resp['message']=='Invalid token'){
              this.db.sessionExpire();
              return;
            }
            this.db.presentAlert(resp['status'],resp['message'])
          }
        })
        
      } else {
        swal("Your data is safe!");
      }
    });
  }

  viewDetail(data:any){
    console.log('viewDetail');
    
  }
  
  backClicked() {
    this._location.back();
  }
}
