import { Component, Inject, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { FormGroup, Validators } from '@angular/forms';
import { DatabaseService } from 'src/app/_services/database.service';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'assign-item-model',
  templateUrl: './assign-item.component.html'
})
export class AssignItemModelComponent implements OnInit {
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: {},public db:DatabaseService,
  private _location: Location) { }
  // data:any = this.db.redeemRequestData
  ngOnInit() {
    this.getTypes();
    this.getItems();
    this.formsInit(this.data);
  }
  
  form: FormGroup;
  formsInit(data:any) {
    console.log(data);
    
    this.form = this.db.formBuilder.group({
      type: [null ,[Validators.required]],
      typeData: [null ,[Validators.required]],
      delivery_note: [null ,[Validators.required]],
      item: [{} ,[Validators.required]],
      qty: [0 ,[Validators.required]],
      itemInfo: [[] ,[Validators.required]],
    })
    console.log(this.form.value);
    
  }
  validate(){
    if(this.form.value.qty == 0)return;
    console.log(this.form.value.qty,this.form.value.item.qty);
    
    if(parseInt(this.form.value.qty) > parseInt(this.form.value.item.qty)){
      setTimeout(() => {
        this.form.controls['qty'].setValue(0)
      }, 100);
    };
  }

  backClicked() {
    this._location.back();
  }
  items:any=[];
  getItems(){
    let reqData = {
      limit : 100000,
      start : 0,
      filter : {}
    }
    this.db.presentLoader();
    this.db.postReq(reqData,'pop_gift/list').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.items=resp['result'].data;
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errHandler(err);
      this.db.errDismissLoader();
    })
  }
  typeData:any=[];
  getTypes(){
    this.db.postReq({},'pop_gift/issue_type').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.typeData=resp['result'].data;
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errHandler(err);
      this.db.errDismissLoader();
    })
  }
  filteredassignTypeData:any=[];
  assignTypeData:any=[];
  getTypeData(){
    this.db.postReq({filter:{cust_type_id:this.form.value.type.cust_type_id},start:0,limit:10000000},this.form.value.type.cust_type_id ? 'pop_gift/dr_list' : 'pop_gift/sales_user').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.assignTypeData=resp['result'].data;
        this.filteredassignTypeData=resp['result'].data;
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errHandler(err);
      this.db.errDismissLoader();
    })
  }
  onSubmit(){
    if(this.form.invalid){
      this.form.markAllAsTouched()
      return;
    }
    let reqData = this.form.value
    this.db.presentLoader();
    this.db.postReq(reqData,'pop_gift/assign').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.db.successAlert(resp['status'],resp['message']);
        this.db.matdialog.closeAll();
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errHandler(err);
      this.db.errDismissLoader();
    })
    
  }
  addToList(){
    if(this.form.value.item.qty < this.form.value.qty ){return}
    let i = this.form.value.itemInfo.findIndex(r=>r.id == this.form.value.item.id);
    if(i!= -1) this.form.value.itemInfo.splice(i,1);
    let item = this.form.value.item;
    item.input_qty = this.form.value.qty;
    this.form.controls['itemInfo'].setValue([...this.form.value.itemInfo,item]);
    this.form.controls['item'].setValue({});
    this.form.controls['qty'].setValue(0);
    
  }
  removeItem(i){
    this.form.value.itemInfo.splice(i,1);
  }
}
