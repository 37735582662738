import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';

@Component({
  selector: 'app-us-distributors-lis',
  templateUrl: './us-distributors-lis.component.html'
})
export class UsDistributorsLisComponent implements OnInit {
  data:any=[];
  constructor(private _location: Location) { }

  ngOnInit() {
    this.data = [
      {
        "date":"28 May 2020",
        "createby":"Manoj Kumar",
        "firm":"Lanco Infratech",
        "person": "Sandeep",
        "mobile": "9967123470",
        "address": "Haryana - Faridabad",
        "target": "3,50,000",
        "achieve": "3,75,000",
        "targetbg": "light-green",
        "outstanding": "25,000",
        "assign": "Rahul Tyagi",
      },
      {
        "date":"26 May 2020",
        "createby":"Satish",
        "firm":"Nilkamal Plastics",
        "person": "Sanjay Yadav ",
        "mobile": "9967123470",
        "address": "Punjab - Amritsar",
        "target": "3,00,000",
        "achieve": "2,00,000",
        "targetbg": "light-pink",
        "outstanding": "1,00,000",
        "assign": "Sumit Rawat",
      },
      {
        "date":"24 May 2020 ",
        "createby":"Nitesh Tiwari",
        "firm":"Sobha Ltd.",
        "person": "Mahesh ",
        "mobile": "8010025731",
        "address": "Maharashtra - Pune",
        "target": "2,50,000",
        "achieve": "2,75,000",
        "targetbg": "light-green",
        "outstanding": "25,000",
        "assign": "Ajay Nain",
      },
      {
        "date":"22 May 2020  ",
        "createby":"Jagjeet Singh",
        "firm":"The Himalaya Drug Company",
        "person": "Siddharth ",
        "mobile": "9289158575",
        "address": "Haryana - Panchkula",
        "target": "5,00,000",
        "achieve": "4,00,000",
        "targetbg": "light-pink",
        "outstanding": "1,00,000",
        "assign": "Rahul Tyagi",
      },
      {
        "date":"22 May 2020  ",
        "createby":"Aman Aggarwal",
        "firm":"Exide Industries",
        "person": "Sanjeev Gandhi",
        "mobile": "7823563490",
        "address": "West Bengal - Kolkata",
        "target": "5,00,000",
        "achieve": "4,50,000",
        "targetbg": "light-pink",
        "outstanding": "50,000",
        "assign": "Sumit Rawat",
      },
      {
        "date":"22 May 2020  ",
        "createby":"Aman Gupta",
        "firm":"Gupta's sons pvt. ltd.",
        "person": "Mahesh ",
        "mobile": "9056781212",
        "address": "Rajasthan - Bhilwara",
        "target": "7,00,000 ",
        "achieve": "5,00,000",
        "targetbg": "light-pink",
        "outstanding": " 2,00,000",
        "assign": "Ajay Nain",
      },
      {
        "date":"22 May 2020  ",
        "createby":"Vivek Saini",
        "firm":"Mangla's sons pvt. ltd.",
        "person": "Ajit Dubey  ",
        "mobile": "9056781212",
        "address": "Haryana - Mahendragarh",
        "target": "6,00,000",
        "achieve": "6,20,000",
        "targetbg": "light-green",
        "outstanding": " 20,000",
        "assign": "Kapil Singla",
      },
      {
        "date":"20 May 2020  ",
        "createby":"Gaurav Pandey",
        "firm":"Ballarpur Industries Limited",
        "person": "Pardeep Narwal",
        "mobile": "9712345612",
        "address": "Gujarat - Jamnagar",
        "target": "3,50,000",
        "achieve": "3,75,000",
        "targetbg": "light-green",
        "outstanding": "25,000",
        "assign": "Suraj Kumar",
      },
      {
        "date":"21 May 2020  ",
        "createby":"Vipin Rawat",
        "firm":"Lakshmi Machine Works",
        "person": "Ajay Sharma",
        "mobile": "9912345613",
        "address": "Punjab - Patiala",
        "target": "2,00,000",
        "achieve": "3,00,000",
        "targetbg": "light-pink",
        "outstanding": "1,00,000",
        "assign": "Vipin Sharma",
      },
      {
        "date":"18 May 2020",
        "createby":"Sunil Verma",
        "firm":"POSCO Indias",
        "person": "Sachin Jain",
        "mobile": "7890152345",
        "address": "Himachal Pradesh - Kangra",
        "target": "1,50,000",
        "achieve": "1,25,000",
        "targetbg": "light-pink",
        "outstanding": "25,000",
        "assign": "A.P. Singh",
      },
    ];
  }
  backClicked() {
    this._location.back();
  }

}
