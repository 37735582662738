import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import {MatDialog} from '@angular/material/dialog';
import { ProductSizeDetailModelComponent } from '../product-size-detail-model/product-size-detail-model.component';
import { DatabaseService } from 'src/app/_services/database.service';
import * as moment from 'moment';
import swal from 'sweetalert';
@Component({
  selector: 'app-product-size-list',
  templateUrl: './product-size-list.component.html',
})
export class ProductSizeListComponent implements OnInit {

  data:any=[];
  dataNotFound=false
  reqSent=false
  autoFocus?: boolean = true;
  
  constructor(
    private _location: Location,
    public dialog: MatDialog,
    public db:DatabaseService
    ) { }
    ngOnInit() {
      this.todayDate =  moment(new Date ()).format('YYYY-MM-DD')
      this.getData();
    }
    todayDate :any
    pageNo:any=1;
    tempPageNo =1;
    totalPages:any
    start:any=0;
    limit:any=50;
    totalLength:any;
    filter:any={};
    getData(){
      this.dataNotFound = false;
      this.reqSent = false;
      let reqData = {
        limit : this.limit,
        start : this.start,
        filter : this.filter
      }
      this.db.presentLoader();
      this.db.postReq(reqData,'master/product_size').subscribe(resp=>{
        this.reqSent = true;
        if(resp['status'] == 'success'){
          this.data = resp['result'].data;
          if(!this.data.length) this.dataNotFound = true;
          this.totalLength = resp['result'].totalCount;
          this.totalPages = Math.ceil(this.totalLength / this.limit);
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        
      })
    }
    pagination(action){
      
      if(action == 'pageNo'){
        if(this.pageNo > 0 && this.pageNo <=this.totalPages){
          this.start = this.limit*(this.pageNo-1)
        }else{
          this.pageNo = Math.ceil(this.totalLength / this.data.length);
        }
      }
      else if(action=='next'){
        
               if(this.totalLength == (this.start+this.data.length)) return;

        this.start = this.start+this.limit
        this.pageNo ++ ;
      }else{
        if(this.pageNo == 1) return
        this.start = this.start-this.limit
        this.pageNo -- ;
      }
      this.getData();
      this.tempPageNo = this.pageNo
    }
    refresh(){
      this.start = 0;
      this.limit = 50;
      this.filter = {};
      this.getData();
    }
    delete(data){
      swal({
        title: "Are you sure?",
        text: "You want to delete this",
        icon: "warning",
        buttons: ["Cancel", "Confirm"],
        dangerMode: true,
      })
      .then((willDelete) => {        
        if (willDelete) {
          this.db.presentLoader();
          data.status = data.status == 0 ? 1 : 0;
          data.size_id = data.id;
          this.db.postReq(data,'master/delete_product_size').subscribe(resp=>{
            if(resp['status'] == 'success'){
              this.getData();
              swal("Deleted", {
                icon: "success",
              });
            }else{
              if(resp['message']=='Invalid token'){
                this.db.sessionExpire();
                return;
              }
              this.db.presentAlert(resp['status'],resp['message'])
            }
          })
          
        } else {
          swal("Your data is safe!");
        }
      });
    }
    backClicked() {
      this._location.back();
    }
    openDetail(row): void {
      console.log(row);
      
      const dialogRef = this.dialog.open(ProductSizeDetailModelComponent, {
        width: '768px',
        autoFocus: false,
        data: row
      });
      
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
      });
    }
  

}
