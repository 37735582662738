import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { DatabaseService } from 'src/app/_services/database.service';
import { DialogService } from 'src/app/_services/dialog.service';

@Component({
  selector: 'app-product-discount',
  templateUrl: './product-discount.component.html'
})
export class ProductDiscountModelComponent implements OnInit {
  
  constructor(public matdialog:MatDialog, @Inject(MAT_DIALOG_DATA) public data :any ,public db :DatabaseService) { }
  
  // ngOnInit() {
  //   this.formsInit();
  // }
  ngOnInit() {
    console.log(this.data);
    this.getProducts();
    if(this.data.data.id){
      this.formsInit(this.data.data);
    }    else{
      this.formsInit({});
    }
    this.getCust();
  }
  custData:any=[]
  filteredCustData:any=[]
  getCust(){
    let reqData = {
      limit : 100,
      start : 0,
      filter : {}
    }
    this.db.presentLoader();
    this.db.postReq(reqData,'invoice/dr_list').subscribe(resp=>{
      if(resp['status'] == 'success'){
        this.custData = resp['result'].data;
        this.filteredCustData = resp['result'].data;
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
  }

  productsData:any=[] ;
  filteredProductsData:any=[]
  
  getProducts(){
    let reqData = {
      limit : 100000,
      start : 0,
      filter:{}
      // filter : {product_name:this.filter.product_name}
    }
    this.db.presentLoader();
    this.db.postReq(reqData,'order/product_list').subscribe(async resp=>{
      if(resp['status'] == 'success'){
        await resp['result'].data.map(r=>{
          r.searchName = r.product_name.replace(/ /g,'').toLowerCase();
        })
        this.filteredProductsData = resp['result'].data;
        this.productsData = resp['result'].data;
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
  }
  getDetail(id){
    this.db.presentLoader();
    
    this.db.postReq({'role_id':id},'master/role_detail').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.formsInit(resp['data']['detail']);
        
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errHandler(err);
    })
    
  }
  form: FormGroup;
  
  formsInit(data:any) {
    console.log(data);
    
    this.form = this.db.formBuilder.group({
      name :[data.name ? data.name : null ,[Validators.required]],
      customer_id :[this.data.data.customer_id ,[Validators.required]],
      product_id :[data.product_id ? data.product_id : null ,[Validators.required]],
      min_qty :[data.min_qty ? data.min_qty : null ,[Validators.required]],
      discount :[data.discount ? data.discount : null ,[Validators.required]],
      discount_product_id :[data.id ? data.id : null ,[]],
      type:[this.data.data.customer_id ? 'detail' : '']
    })
  }
  onSubmit(){
    console.log(this.form);
    
    if(this.form.invalid){
      this.form.markAllAsTouched();
      return
    }
    console.log(this.form.value);
    this.db.presentLoader();
    this.db.postReq(this.form.value,this.data.data.id ? 'dis_network/update_product_discount' : 'dis_network/add_product_discount').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.db.successAlert(resp['status'],resp['message']);
        this.matdialog.closeAll()        
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errDismissLoader();
    })
    
  }
  
  
  
  
}
