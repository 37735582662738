import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';

@Component({
  selector: 'app-us-target-list',
  templateUrl: './us-target-list.component.html'
})
export class UsTargetListComponent implements OnInit {

  data:any=[];
  tabActiveType:any={};
  constructor(private _location: Location) 
  {
    this.tabActive('tab1');
   }

  ngOnInit() {
    this.data = new Array(10);
  }
  backClicked() {
    this._location.back();
  }
  tabActive(tab:any)
  {
    this.tabActiveType = {};
    this.tabActiveType[tab] = true; 
  }

}
