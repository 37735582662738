import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
// import { PaymentStatusModelComponent } from '../../payment/payment-status-model/payment-status-model.component';
import { MatDialog } from '@angular/material';
import { DatabaseService } from 'src/app/_services/database.service';
import swal from 'sweetalert';
import * as moment from 'moment';
import { CustomEventsService } from 'src/app/_services/custom-events.service';
import { ActivatedRoute } from '@angular/router';
// import { RemarkModelComponent } from '../../../remark-model/remark-model.component';


@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html'
})
export class OrderListComponent implements OnInit {
  
  data:any=[];
  dataNotFound=false
  reqSent=false
  statusData:any=[];
  tabActiveType:any={};
  
  constructor(public activatedRoute:ActivatedRoute,public event:CustomEventsService,private _location: Location, public dialog: MatDialog,public db:DatabaseService) 
  {
    this.getOrderStatus()
    this.filter.cust_type='';
    this.todayDate =  moment(new Date ()).format('YYYY-MM-DD')
    
    this.event.subscribe('refresh-order-list', (data: any) => {
      console.log('refresh');
      
      this.start = 0;
      this.limit = 50;
      let order_status = this.filter.order_status; 
      this.filter={};
      this.filter.order_status = order_status;    
      this.getOrderStatus();
    })
  }
  
  ngOnInit() {
    
    
  }
  
  backClicked() {
    this._location.back();
  }
  
  
  tabActive(order_status:any)
  {
    // this.tabActiveType = {};
    // this.tabActiveType[tab] = true; 
    this.filter.order_status = order_status;
    this.start=0;
    this.limit=50;
    this.getData()
  }
  getOrderStatus(){
    
    this.db.presentLoader();
    this.db.postReq({type:this.activatedRoute.snapshot.params.type},'order/get_order_status').subscribe(resp=>{
      if(resp['status'] == 'success'){
        this.statusData = resp['result'].data;
        this.tabActive( resp['result'].data[0].name);
        // this.getData();
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
  }
  todayDate :any
  pageNo:any=1;
  tempPageNo =1;
  totalPages:any
  start:any=0;
  limit:any=50;
  totalLength:any;
  filter:any={};
  getData(){
    this.dataNotFound = false;
    this.reqSent = false;
    let reqData = {
      limit : this.limit,
      start : this.start,
      filter : this.filter,
      type:this.activatedRoute.snapshot.params.type
    }
    this.db.presentLoader();
    this.db.postReq(reqData,'order/list').subscribe(resp=>{
      this.reqSent = true;
      if(resp['status'] == 'success'){
        this.data = resp['result'].data;
        if(!this.data.length) this.dataNotFound = true;
        this.totalLength = resp['result'].totalCount;
        this.totalPages = Math.ceil(this.totalLength / this.limit);
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
  }
  pagination(action){
    
    if(action == 'pageNo'){
      if(this.pageNo > 0 && this.pageNo <=this.totalPages){
        this.start = this.limit*(this.pageNo-1)
      }else{
        this.pageNo = Math.ceil(this.totalLength / this.data.length);
      }
    }
    else if(action=='next'){
      
      if(this.totalLength == (this.start+this.data.length)) return;
      
      this.start = this.start+this.limit
      this.pageNo ++ ;
    }else{
      if(this.pageNo == 1) return
      this.start = this.start+this.limit
      this.pageNo -- ;
    }
    this.getData();
    this.tempPageNo = this.pageNo
  }
  refresh(){
    this.start = 0;
    this.limit = 50;
    let order_status = this.filter.order_status; 
    this.filter={};
    this.filter.order_status = order_status;    
    this.getData();
  }
  delete(data){
    swal({
      title: "Are you sure?",
      text: "You want delete this order!" ,
      icon: "warning",
      buttons: ["Cancel", "Confirm"],
      dangerMode: true,
    })
    .then((willDelete) => {        
      if (willDelete) {
        this.db.presentLoader();
        data.order_id = data.id;
        this.db.postReq({order_id:data.id},'order/delete').subscribe(resp=>{
          if(resp['status'] == 'success'){
            swal(data.status == 0 ? "Deleted!" : "Restored", {
              icon: "success",
            });
          }else{
            if(resp['message']=='Invalid token'){
              this.db.sessionExpire();
              return;
            }
            this.db.presentAlert(resp['status'],resp['message'])
          }
        })
        
      } else {
        swal("Your data is safe!");
      }
    });
  }
  
  openStatusDialog(): void {
    // const dialogRef = this.dialog.open(PaymentStatusModelComponent, {
    //   width: '400px',
    //   autoFocus: false,
    //   data: {}
    // });
    
    // dialogRef.afterClosed().subscribe(result => {
    //   console.log('The dialog was closed');
    // });
  }
  
  openRemarkDialog(): void {
    // const dialogRef = this.dialog.open(RemarkModelComponent, {
    //   width: '450px',
    //   autoFocus: false,
    //   data: {}
    // });
    
    // dialogRef.afterClosed().subscribe(result => {
    //   console.log('The dialog was closed');
    // });
  }
  
}
