import { Component, Inject, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { FormGroup, Validators } from '@angular/forms';
import { DatabaseService } from 'src/app/_services/database.service';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'add-stock-model',
  templateUrl: './add-stock.component.html'
})
export class AddStockModelComponent implements OnInit {
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,public db:DatabaseService,
  private _location: Location) { 
    console.log(this.data);
    
  }
  // data:any = this.db.redeemRequestData
  ngOnInit() {
   
    this.formsInit(this.data);
  }
  qtyhandle(){
    console.log('test',this.data.rate * this.form.value.qty);
    
    this.form.controls['total_amount'].setValue(this.data.rate * this.form.value.qty)
  }
  form: FormGroup;
  formsInit(data:any) {
    console.log(data);
    
    this.form = this.db.formBuilder.group({
      pop_gift_id: [data.encrypt_id ,[Validators.required]],
      qty: [null ,[Validators.required]],
      total_amount: [null ,[Validators.required]],
    })
    console.log(this.form.value);
    
  }
 
  onSubmit(){
    if(this.form.invalid){
      this.form.markAllAsTouched()
      return;
    }
    let reqData = this.form.value
    this.db.presentLoader();
    this.db.postReq(reqData,'pop_gift/update_stock').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.db.successAlert(resp['status'],resp['message']);
        this.db.matdialog.closeAll();
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errHandler(err);
      this.db.errDismissLoader();
    })
    
  }
  
}
