import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';

@Component({
  selector: 'app-payment-status-model',
  templateUrl: './payment-status-model.component.html'
})
export class PaymentStatusModelComponent implements OnInit {

  constructor(private _location: Location) { }

  ngOnInit() {
  }
  
  backClicked() {
    this._location.back();
  }
}
