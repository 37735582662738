import { Component, OnInit, Renderer2 } from '@angular/core';
import {Location} from '@angular/common';
import { MatDialog } from '@angular/material';
import * as moment from 'moment';
import { DatabaseService } from '../_services/database.service';
import swal from 'sweetalert';

@Component({
  selector: 'app-attandance-list',
  templateUrl: './attandance-list.component.html'
})
export class AttandanceListComponent implements OnInit {
  
  type:any = 'date';
  month:any
  data:any=[];
  dataNotFound=false
  reqSent=false
  autoFocus?: boolean = true;
  
  constructor(
    private _location: Location,
    public dialog: MatDialog,
    public db:DatabaseService
    ) { }
    ngOnInit() {
      this.filter.status = ''
      this.todayDate =  moment(new Date ()).format('YYYY-MM-DD')
      this.getData();
    }
    monthDates:any=[];
    months:any=[];
    todayDate :any
    pageNo:any=1;
    tempPageNo =1;;
    totalPages:any
    start:any=0;
    limit:any=50;
    totalLength:any;
    attendanceCount:any={};
    filter:any={};
    getData(){
      if(this.type == 'month'){
        if(!this.month) this.month = this.months[0].value;
      }
      this.dataNotFound = false;
      this.reqSent = false;
      let reqData = {
        limit : this.limit,
        start : this.start,
        filter : this.filter,
        type:this.type,
        month:this.month
      }
      this.db.presentLoader();
      this.db.postReq(reqData,'attendance/list').subscribe(resp=>{
        this.reqSent = true;
        if(resp['status'] == 'success'){
          this.data = resp['result'].data;
          if(!this.data.length) this.dataNotFound = true;
          this.totalLength = resp['result'].totalCount;
          this.attendanceCount = resp['result'].attendanceCount;
          this.monthDates = resp['result'].monthDates
          this.months = resp['result'].months
          this.totalPages = Math.ceil(this.totalLength / this.limit);
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        this.db.errHandler(err);
      })
    }
    pagination(action){
      
      if(action == 'pageNo'){
        if(this.pageNo > 0 && this.pageNo <=this.totalPages){
          this.start = this.limit*(this.pageNo-1)
        }else{
          this.pageNo = Math.ceil(this.totalLength / this.data.length);
        }
      }
      else if(action=='next'){
        
        if(this.totalLength == (this.start+this.data.length)) return;
        
        this.start = this.start+this.limit
        this.pageNo ++ ;
      }else{
        if(this.pageNo == 1) return
        this.start = this.start-this.limit
        this.pageNo -- ;
      }
      this.getData();
      this.tempPageNo = this.pageNo
    }
    refresh(){
      this.start = 0;
      this.limit = 50;
      this.filter = {};
      this.getData();
    }
    
    backClicked() {
      this._location.back();
    }  
    
    downloadExcel(){
      let reqData = {
        filter : this.filter,
        date : this.todayDate,
        date_from : this.filter.date_from,
        date_to : this.filter.date_to,
      }

      this.db.presentLoader();
      this.db.postReq(reqData,'attendance/list/excel').subscribe(resp=>{
        this.reqSent = true;
        if(resp['status'] == 'success'){
          this.db.exportAsExcelFile(resp['result'].data,resp['result'].file_name ? resp['result'].file_name : 'exported_data');
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        this.db.errHandler(err);
      })
    }

    downloadExclusiveKilometerReport(){
      let reqData = {
        filter : this.filter
      }
      this.db.presentLoader();
      this.db.postReq(reqData,'attendance/executive/list/excel').subscribe(resp=>{
        this.reqSent = true;
        if(resp['status'] == 'success'){
          this.db.exportAsExcelFile(resp['result'].data,resp['result'].file_name ? resp['result'].file_name : 'exported_data');
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        this.db.errHandler(err);
      })
    }

    restartAttendance(data){
      swal({
        title: "Are you sure?",
        text:  "You want to restart stop time for  this attendance!",
        icon: "warning",
        buttons: ["Cancel", "Confirm"],
        dangerMode: true,
      })
      .then((willDelete) => {        
        if (willDelete) {
          this.db.presentLoader();
          let reqData = {
            attendance_id : data.id
          }
          // data.status = data.status == 0 ? 1 : 0;
          // data.user_id = data.id;
          this.db.postReq(reqData,'attendance/restart').subscribe(resp=>{
            if(resp['status'] == 'success'){
              swal("Restarted", {
                icon: "success",
              });
              this.getData();
            }else{
              if(resp['message']=='Invalid token'){
                this.db.sessionExpire();
                return;
              }
              this.db.presentAlert(resp['status'],resp['message'])
            }
          })
          
        } else {
          swal("Your data is safe!");
        }
      });
    }
  }
  