import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-expense-detail-model',
  templateUrl: './expense-detail-model.component.html',
})
export class ExpenseDetailModelComponent implements OnInit {

  gallery:any=[];
  // @Inject(MAT_DIALOG_DATA) public data: any

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    // setTimeout(() => {
      
    //   console.log(this.data);
    // }, 2000);
    
   }


  ngOnInit() {
    this.gallery = new Array(4);
  }
}
