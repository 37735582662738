import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { FormGroup, Validators } from '@angular/forms';
import { DatabaseService } from 'src/app/_services/database.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-product-group-add',
  templateUrl: './product-group-add.component.html'
})
export class ProductGroupAddComponent implements OnInit {
  
  constructor(private _location: Location,public db:DatabaseService,public actRoute:ActivatedRoute) { }
  
  ngOnInit() {
    if(this.actRoute.snapshot.params.id){
      this.getDetail(this.actRoute.snapshot.params.id)
    }    else{
      this.formsInit({});
    }
  }
  getDetail(id){
    this.db.presentLoader();
    
    this.db.postReq({'group_id':id},'master/product_group_detail').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.formsInit(resp['data']['detail']);
        
      }else{
if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errHandler(err);
    })
    
  }
  form: FormGroup;
  
  formsInit(data:any) {
    this.form = this.db.formBuilder.group({
      code: [data.code ? data.code : null , []],
      name: [data.name ? data.name : null ,[Validators.required]],
      description: [data.description ? data.description : null ],
      status: [data.status ? data.status.toString() : null , [Validators.required]],
      group_id: [data.id ? data.id : null],
    })
  }
  onSubmit(){
    if(this.form.invalid){
      this.form.controls['name'].markAsTouched();
      this.form.controls['code'].markAsTouched();
      this.form.controls['description'].markAsTouched();
      this.form.controls['status'].markAsTouched();
      return
    }
    console.log(this.form.value);
    this.db.presentLoader();
    this.db.postReq(this.form.value,this.actRoute.snapshot.params.id ? 'master/update_product_group' : 'master/add_product_group').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.db.successAlert(resp['status'],resp['message']);
        this.backClicked();
      }else{
if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errDismissLoader();
    })
    
  }
  
  backClicked() {
    this._location.back();
  }
  
}
