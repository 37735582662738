import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { FormGroup, Validators } from '@angular/forms';
import { DatabaseService } from 'src/app/_services/database.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-product-category-add',
  templateUrl: './product-category-add.component.html'
})
export class ProductCategoryAddComponent implements OnInit {
  
  constructor(private _location: Location,public db:DatabaseService,public actRoute:ActivatedRoute) { }
  
  ngOnInit() {
    if(this.actRoute.snapshot.params.id){
      this.getDetail(this.actRoute.snapshot.params.id)
    }    else{
      this.formsInit({});
    }
    this.getData();
  }

  data:any={}
  filteredData:any={};
  getData(){

    this.db.postReq({start:0,limit:1000,filter:{}},'master/brand').subscribe(resp=>{
      if(resp['status'] == 'success'){
        this.data.brandData = resp['result'].data        ;
        this.filteredData.brandData = resp['result'].data        
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    })
     
  }

  getDetail(id){
    this.db.presentLoader();
    
    this.db.postReq({'category_id':id},'master/product_category_detail').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.formsInit(resp['data']['detail']);
        
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errHandler(err);
    })
    
  }
  changeListener($event): void {
    console.log($event);
    this.form.controls['image_path_loading'].setValue(true);
    
    var file = $event.target.files[0];
    var reader = new FileReader();
    var image
    reader.onloadend = function () {
      image = reader.result;
    }
    setTimeout(() => {
      console.log(image);
      let reqData = {base64:image}
      this.db.presentLoader();
      this.db.postReq(reqData,'base64_to_file').subscribe(resp=>{
        this.db.dismissLoader();
        if(resp['status'] == 'success'){
         this.form.controls['image_path'].setValue(resp['response'].fileName);
         this.form.controls['image_path_loading'].setValue(false);
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        this.db.errDismissLoader();
      })
    }, 100);
    
    reader.readAsDataURL(file);
    
  }
  
  form: FormGroup;
  
  formsInit(data:any) {
    this.form = this.db.formBuilder.group({
      name: [data.name ? data.name : null ,[Validators.required]],
      description: [data.description ? data.description : null ],
      status: [data.status ? data.status.toString() :"1" , [Validators.required]],
      category_id: [data.id ? data.id : null],
      image_path: [data.filename ? data.filename : null , []],
      image: [],
      image_path_loading:[false],
    })
  }
  onSubmit(){
    if(this.form.invalid){
      this.form.controls['name'].markAsTouched();
      this.form.controls['code'].markAsTouched();
      this.form.controls['description'].markAsTouched();
      this.form.controls['status'].markAsTouched();
      return
    }
    console.log(this.form.value);
    this.db.presentLoader();
    this.db.postReq(this.form.value,this.actRoute.snapshot.params.id ? 'master/update_product_category' : 'master/add_product_category').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.db.successAlert(resp['status'],resp['message']);
        this.backClicked();
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errDismissLoader();
    })
    
  }
  
  backClicked() {
    this._location.back();
  }
  
}
